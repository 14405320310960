import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import styles from './OfferSection.module.scss';
import { height } from 'dom7';

export interface AccordionItemProps {
  id: string;
  index: number;
  activeIndex: number;
  onToggle: any;
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
  title: string;
  desc: string;
  imgSrc: string;
  imgAlt: string;
  imgWidth: number;
  imgHeight: number;
  imageActive?: boolean;
  scrollPosition?: number;
  maxScrollPositionPerSection?: number;
}

const AccordionItem = ({
  id,
  index,
  activeIndex,
  onToggle,
  mobile,
  tablet,
  desktop,
  title,
  desc,
  imgSrc,
  imgAlt,
  imgWidth,
  imgHeight,
  imageActive,
  scrollPosition,
  maxScrollPositionPerSection,
}: AccordionItemProps) => {
  const isActive = index === activeIndex;
  const ref = React.useRef(null);
  const imageAccordionRef = React.useRef(null);
  const [innerHeightState, setInnerHeightState] = useState(100);
  const [imgHeightDesktop, setImgHeightDesktop] = useState(0);

  const fadeInH2 = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const fadeInP = {
    hidden: { y: 400, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };
  const fadeInImg = {
    hidden: { y: innerHeightState + 200, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  const handleClick = () => {
    onToggle(index);
  };

  const checkScreenSize = () => {
    setInnerHeightState(window.innerHeight);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    setImgHeightDesktop((innerHeightState - imageAccordionRef?.current?.getBoundingClientRect().height) / 2);
    console.log('innerHeightState', innerHeightState);
    console.log(
      'imageAccordionRef?.current?.getBoundingClientRect().height',
      imageAccordionRef?.current?.getBoundingClientRect().height,
    );
    console.log('top', (innerHeightState - imageAccordionRef?.current?.getBoundingClientRect().height) / 2);
  }, [innerHeightState, imageAccordionRef.current]);

  return (
    <div key={`divAccordionItem${index}`} id={id} ref={ref}>
      {mobile && (
        <div
          className={styles.accordionItem}
          style={{
            display: 'flex',
            flexDirection: tablet ? (index % 2 === 0 ? 'row' : 'row-reverse') : 'column',
          }}
        >
          <div style={{ width: tablet ? '50%' : '100%' }}>
            <h2 key={`h2AccordionItem${index}`} role="presentation" className={'didacticH2'}>
              {title}
            </h2>
            <motion.p
              key={`pAccordionItem${index}`}
              initial={fadeInP.hidden}
              animate={fadeInP.visible}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
              className={'didacticP'}
            >
              {desc}
            </motion.p>
          </div>
          <div style={{ width: tablet ? '50%' : '100%' }}>
            <motion.img
              initial={fadeInImg.hidden}
              animate={fadeInImg.visible}
              transition={{ duration: 1, ease: 'easeInOut' }}
              key={`imgAccordionItem${index}`}
              style={{ width: '100%', boxSizing: 'border-box', border: '1px solid #281B24', borderRadius: '16px' }}
              loading="lazy"
              src={`${process.env.CDN_PROVIDER_URL}${imgSrc}`}
              alt={imgAlt}
            />
          </div>
        </div>
      )}

      {desktop && (
        <div style={{ marginTop: '24px' }}>
          {!isActive && (
            <motion.h3
              key={`h3AccordionItem${index}`}
              role="presentation"
              className={'didacticH3'}
              style={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              {title}
            </motion.h3>
          )}
          {isActive && (
            <div style={{ display: 'flex', gap: '20px' }}>
              <div style={{ flex: 2 }}>
                <motion.h2
                  initial={fadeInH2.hidden}
                  animate={fadeInH2.visible}
                  transition={{ duration: 0.2, ease: 'easeInOut' }}
                  key={`h2AccordionItem${index}`}
                  role="presentation"
                  className={'didacticH2'}
                  onClick={handleClick}
                >
                  {title}
                </motion.h2>
                <motion.p
                  key={`pAccordionItem${index}`}
                  initial={fadeInP.hidden}
                  animate={fadeInP.visible}
                  transition={{ duration: 0.5, ease: 'easeInOut' }}
                  className={'didacticP'}
                >
                  {desc}
                </motion.p>
              </div>
            </div>
          )}
          <div style={{ flex: 2, display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
            <motion.img
              ref={imageAccordionRef}
              // initial={fadeInImg.hidden}
              // animate={fadeInImg.visible}
              // transition={{ duration: 0.1, ease: 'easeInOut' }}
              key={`imgAccordionItem${index}`}
              style={{
                display: imageActive ? 'block' : 'none',
                // marginBottom: '56px',
                position: imageActive ? 'absolute' : 'relative',
                top: 0,
                right: '0',
                opacity:
                  index == activeIndex
                    ? 1 - (scrollPosition % maxScrollPositionPerSection) / maxScrollPositionPerSection + 0.7
                    : index == activeIndex + 1
                    ? (scrollPosition % maxScrollPositionPerSection) / maxScrollPositionPerSection - 0.7
                    : index == activeIndex - 1
                    ? 1 - (scrollPosition % maxScrollPositionPerSection) / maxScrollPositionPerSection - 0.7
                    : 0,
                // width: '40%',
                // maxWidth: '956px',
                height: '100vh',
              }}
              loading="lazy"
              src={`${process.env.CDN_PROVIDER_URL}${imgSrc}`}
              alt={imgAlt}
              // width={1.8 * imgWidth}
              // height={1.8 * imgHeight}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AccordionItem;

import { useInView, useMotionValue, useMotionValueEvent } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import el from '../../intl/locales/el';
import en from '../../intl/locales/en';
import ScrollHandler from '../ScrollHandler/ScrollHandler';
import { useScrollTrigger } from '../ScrollHandler/ScrollTriggerProvider';
import AccordionItem, { AccordionItemProps } from './AccordionItem';
import styles from './OfferSection.module.scss';

const marginTop = 80; // according to nav max height for desktop
const maxScrollPositionPerSection = 4000;

const OfferSection = ({ id, t }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [mobile, setMobile] = useState(false);
  const [tablet, setTablet] = useState(false);
  const [desktop, setDesktop] = useState(false);

  const refTwo = useRef(null);
  const isInViewSection = useInView(refTwo, { amount: 0.2, once: true });
  const [imageActive, setImageActive] = useState(false);

  const [preventScroll, setPreventScroll] = useState(false);

  const [previousLatestScrollPosition, setPreviousLatestScrollPosition] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(50000);
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollPositionRef = useRef(scrollPosition);
  const scrollYProgress = useScrollTrigger();
  // const scrollYProgress = useMotionValue(scrollPosition);
  const [deltaY, setDeltaY] = useState(0);

  const router = useRouter();
  const { locale } = router;

  const [timer, setTimer] = useState(null);

  const accordionItems: Partial<AccordionItemProps>[] = [
    {
      title: t.offerSection2,
      desc: t.offerSection3,
      imgSrc: '/newassets/what-we-offer-wedding-website.jpeg',
      imgAlt: 'what-we-offer-wedding-website',
      imgWidth: 336,
      imgHeight: 328,
    },
    {
      title: t.offerSection4,
      desc: t.offerSection5,
      imgSrc: '/newassets/what-we-offer-invitations.jpeg',
      imgAlt: 'what-we-offer-invitations',
      imgWidth: 425,
      imgHeight: 328,
    },
    {
      title: t.offerSection6,
      desc: t.offerSection7,
      imgSrc: '/newassets/manage_guests_mobile.jpeg',
      imgAlt: 'manage_guests_mobile',
      imgWidth: 425,
      imgHeight: 328,
    },
    {
      title: t.offerSection8,
      desc: t.offerSection9,
      imgSrc: '/newassets/what-we-offer-guest-availability.jpeg',
      imgAlt: 'what-we-offer-guest-availability',
      imgWidth: 425,
      imgHeight: 328,
    },
    {
      title: t.offerSection10,
      desc: t.offerSection11,
      imgSrc: '/newassets/guest_wedding_app_mobile.jpeg',
      imgAlt: 'guest_wedding_app_mobile',
      imgWidth: 425,
      imgHeight: 328,
    },
  ];

  const handleScroll = (deltaY) => {
    console.log('ela1', refTwo.current.getBoundingClientRect());
    console.log('ela2', window.scrollY);
    console.log('ela3', deltaY);

    // // Clear the previous timer if it exists
    // if (timer) {
    //   clearTimeout(timer);
    // }

    if (deltaY != 0) {
      // const timer = setTimeout(async () => {
      if (deltaY) {
        setDeltaY(deltaY);
        setScrollHeight(window.scrollY);

        // const newPosition =
        //   scrollPosition + (deltaY >= 0 ? maxScrollPositionPerSection : -maxScrollPositionPerSection);
        const newPosition = deltaY;

        setScrollPosition(newPosition);
        console.log(`New position is ${newPosition}`);

        if (newPosition <= 0) {
          console.log('MIKROTERO TOU MIDEN');
          setPreventScroll(false);
          document.body.style.overflow = 'scroll';
          setScrollPosition(0);
        }

        if (newPosition <= maxScrollPositionPerSection) setActiveIndex(0);
        else if (newPosition > maxScrollPositionPerSection && newPosition <= 2 * maxScrollPositionPerSection)
          setActiveIndex(1);
        else if (newPosition > 2 * maxScrollPositionPerSection && newPosition <= 3 * maxScrollPositionPerSection)
          setActiveIndex(2);
        else if (newPosition > 3 * maxScrollPositionPerSection && newPosition <= 4 * maxScrollPositionPerSection)
          setActiveIndex(3);
        else if (newPosition > 4 * maxScrollPositionPerSection) setActiveIndex(4);

        if (newPosition >= maxScrollPositionPerSection * 5) {
          console.log(`MEGALITERO TOU ${5 * maxScrollPositionPerSection}`);
          setPreventScroll(false);
          document.body.style.overflow = 'scroll';
          setScrollPosition(maxScrollPositionPerSection * 5);
        }
      } else {
        console.log('do nothing');
      }
      // }, 150);
      // setTimer(timer);
    }
  };

  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    console.log('latest', latest);
    if (latest <= 100) {
      // start offer animation from beginning
      setScrollPosition(0);
      setPreviousLatestScrollPosition(0);
      setActiveIndex(0);
      return;
    }
    // const deltaY = latest - previousLatestScrollPosition;
    // setPreviousLatestScrollPosition(latest);
    // handleScroll(deltaY);
    handleScroll(latest);
  });

  const scrollToPosition = (y: number) => {
    // Flag to indicate if the scrollTo method was called
    let scrollToCalled = false;

    // Scroll event listener
    const onScroll = () => {
      if (scrollToCalled) {
        window.scrollTo({
          top: isNaN(refTwo.current.offsetTop - marginTop) ? 0 : refTwo.current.offsetTop - marginTop,
        });
        // Prevent the default scroll behavior
        // setTimeout(() => window.removeEventListener('scroll', onScroll), 500);
        window.removeEventListener('scroll', onScroll);
        document.body.style.overflow = 'hidden';
        setPreventScroll(isInViewSection);
      }
    };

    // Attach the scroll event listener to the window
    window.addEventListener('scroll', onScroll);

    // Call the scrollTo method with the smooth behavior
    requestAnimationFrame(() =>
      window.scrollTo({
        top: y,
        behavior: 'auto',
      }),
    );

    // Set the flag to indicate that the scrollTo method was called
    scrollToCalled = true;
  };

  useEffect(() => {
    setImageActive(isInViewSection);
    // start offer animation from beginning
    setScrollPosition(0);
    setPreviousLatestScrollPosition(0);
    setActiveIndex(0);
  }, [isInViewSection]);

  const checkScreenSize = () => {
    if (isMobile) {
      setMobile(true);
      if (isTablet) setTablet(true);
      setDesktop(false);
    } else {
      setMobile(false);
      setTablet(false);
      setDesktop(true);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const onToggle = (index) => {
    setActiveIndex(index === activeIndex ? null : index);

    if (index === 0) setScrollPosition(maxScrollPositionPerSection - maxScrollPositionPerSection / 2);
    else if (index === 1) setScrollPosition(2 * maxScrollPositionPerSection - maxScrollPositionPerSection / 2);
    else if (index === 2) setScrollPosition(3 * maxScrollPositionPerSection - maxScrollPositionPerSection / 2);
    else if (index === 3) setScrollPosition(4 * maxScrollPositionPerSection - maxScrollPositionPerSection / 2);
    else if (index === 4) setScrollPosition(5 * maxScrollPositionPerSection - maxScrollPositionPerSection / 2);
  };

  return (
    <section
      id={id}
      ref={refTwo}
      className={styles.OfferSection}
      style={{
        height: mobile ? 'auto' : '100vh',
        backgroundImage: desktop ? `url('${process.env.CDN_PROVIDER_URL}/newassets/main-page-what-we-offer.jpg')` : '',
        marginTop: mobile || tablet ? '72px' : '0',
      }}
    >
      {/*{preventScroll && desktop && <ScrollHandler onScroll={handleScroll} prevent={preventScroll}></ScrollHandler>}*/}
      <div
        className={styles.accordionContainer}
        // style={{
        //   marginBottom: '24px',
        //   width: mobile ? 'auto' : '58vw',
        //   // margin: mobile ? '' : '0 auto',
        //
        //   display: desktop ? 'flex' : 'block',
        //   flexDirection: 'column',
        //   justifyContent: desktop ? 'center' : '',
        //   height: desktop ? '100%' : 'auto',
        // }}
      >
        <div>
          <div>
            <h1 className={'fridayH1'}>{t.offerSection1}</h1>
          </div>
          <div>
            {accordionItems.map((value, index) => (
              <AccordionItem
                id={`accordionItem${index}`}
                key={`accordionItem${index}`}
                index={index}
                activeIndex={activeIndex}
                onToggle={onToggle}
                mobile={mobile}
                tablet={tablet}
                desktop={desktop}
                title={value.title}
                desc={value.desc}
                imgSrc={value.imgSrc}
                imgAlt={value.imgAlt}
                imgWidth={value.imgWidth}
                imgHeight={value.imgHeight}
                imageActive={imageActive}
                scrollPosition={scrollPosition}
                maxScrollPositionPerSection={maxScrollPositionPerSection}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OfferSection;
